import { inject, Injectable, OnDestroy } from '@angular/core';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { PublishEventType } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { ResourceBaseType } from '@shared/api/common/contatns/ResourceBaseType';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { CommentDraftActions } from '@shared/modules/comments/common/constants/comment-draft-actions';
import { filterModelAction } from '@shared/services/communication/shared-subjects/common/helpers/filter-model-action';
import { CommentMail } from '@shared/api/api-loop/models/comment-mail';
import { CommentDraft } from '@shared/api/api-loop/models';
import { merge, Observable, Subject } from 'rxjs';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { CommentDraftModel, isDraftComment } from '@dta/shared/models-api-loop/comment/comment.model';

@Injectable()
export class CommentActionsService implements OnDestroy {
  private readonly userManagerService: UserManagerService = inject(UserManagerService);

  readonly commentMailCreated$: Subject<CommentMail> = new Subject<CommentMail>();
  readonly commentDraftSendLaterUpdate$: Subject<Pick<CommentDraft, 'id' | 'sendLaterDateTime'>> = new Subject<
    Pick<CommentDraft, 'id' | 'sendLaterDateTime'>
  >();

  protected readonly recentlyDeletedDraftParentIds: string[] = [];

  readonly commentDraftParentIdDeleted$: Observable<string> = SharedSubjects._modelAction$.pipe(
    filterModelAction(ResourceBaseType.commentDraft, CommentDraftActions.draftDeleted),
    map(modelAction => {
      this.addToRecentlyDeletedDraftParentIds(modelAction.data.draftCardId);
      return modelAction.data.draftCardId;
    })
  );

  readonly commentDraftCreated$: Observable<CommentDraftModel> = merge(
    // From App (composer -> different window, reply)
    SharedSubjects._modelAction$.pipe(
      filterModelAction(ResourceBaseType.commentDraft, CommentDraftActions.draftCreated),
      map(modelAction => new CommentDraftModel({ ...modelAction.data }))
    ),

    // From sync
    PublisherService.publishedEventsForUser(this.userManagerService.getCurrentUserEmail()).pipe(
      filter(
        event =>
          event.eventType === PublishEventType.Upsert &&
          [ResourceBaseType.commentDraft].includes(event.models?.[0]?.$type)
      ),
      mergeMap(event => event.models),
      filter((model): model is CommentDraftModel => isDraftComment(model))
    )
  ).pipe(
    filter(commentDraft => {
      return !this.recentlyDeletedDraftParentIds.includes(commentDraft.parent.id);
    })
  );

  private addToRecentlyDeletedDraftParentIds(draftId: string): void {
    if (this.recentlyDeletedDraftParentIds.length >= 10) {
      this.recentlyDeletedDraftParentIds.unshift();
    }
    this.recentlyDeletedDraftParentIds.push(draftId);
  }

  ngOnDestroy(): void {
    this.commentMailCreated$.complete();
    this.commentDraftSendLaterUpdate$.complete();
  }
}
